.spin {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

/* VIPButton.css */
@keyframes blink {
    50% {
      opacity: 0.5;
    }
  }
  
.vipButton {
  background-color: red;
  color: white;
  font-size: 20px;
  border: none;
  animation: blink 1s linear infinite;
}