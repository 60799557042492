.rowContainer {
    margin-bottom: 25px;
}

.dividerContainer {
    color: #f47a00 !important;
    border-color: #f47a00 !important;
}

.dividerContainer span {
    font-size: 1.1em;
}

.formButton {
    margin-top: 5px;
}