.modalBody {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.otpInput {
  margin-right: 10px;
}

.resendButton {
  padding: 0;
  height: auto;
}

.Countdown {
  font-size: 15px;
  color: #fd622c;
}

.ant-modal-content {
  font-size: 15px;
  color: #fd622c;
}

.modalCustom {
  top: 250px;
}
