.loginContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loginBox {
  padding: 70px 25px 100px 25px;
  border-radius: 10px;
  -webkit-box-shadow: 0px 5px 10px 0px rgba(50, 50, 50, 0.52);
  -moz-box-shadow: 0px 5px 10px 0px rgba(50, 50, 50, 0.52);
  box-shadow: 0px 5px 10px 0px rgba(179, 176, 176, 0.63)
}

.brandLogo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 330px;
  height: 200px;
}

.brandLogo img {
  width: 260px;
}

.brandLogo p {
  align-self: end;
}

.submitFormButton {
  background-color: #1e3772;
}

.spin {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}