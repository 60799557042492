.headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #1e3772 !important;
    padding-inline: 0 !important;
}

.logoContainer {
    display: flex;
    justify-content: space-between;
    height: 100%; 
}

.rightContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 10px;
    width: 6em;
}

.avatar {
    border-color: #fff;
    background-color: inherit;
}

.avatar h3 {
    font-weight: bold;
}

.logout {
    color: #fff;
    font-size: 2.7em;
}

.logout :hover {
    color: #f47a00;
}

.logout :active {
    color: #fff;
}

.errorContainer {
    margin-block: 1em;
}

/* .errorImage {
    width: 650px;
    
} */